import './App.css'
import { useSelector } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'
import { Header } from './components/header/Header'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import { Login } from './tabs/Login'
import { AppRoutes } from './AppRoutes'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
function App() {
  // LOGGED IN USER
  const { loggedIn } = useSelector((state) => state.selected)
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState('')

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PRO_API}/me`, { withCredentials: true })
      .then((res) => {
        if (res.data.user.nameID) {
          localStorage.setItem('user', res.data.user)
          setUser(res.data.user)
          setLoading(false)
        } else {
          redirectToLogin()
        }
      })
      .catch((err) => {
        console.error(err, '/me')
        redirectToLogin()
      })
  }, [])

  const redirectToLogin = () => {
    window.location.replace(`${process.env.REACT_APP_PRO_API}/login`)
  }

  if (loading) return <p>loading...</p>
  if (user) {
    return (
      <div className='container-fluid'>
        <Header />
        <AppRoutes />
      </div>
    )
  }

  return (
    <div className='d-flex bg-secondary' style={{ height: `100vh` }}>
      <Routes>
        <Route path='*' element={<Login />} />
        <Route path='*' element={<Login />} />
      </Routes>
    </div>
  )
}

export default App
